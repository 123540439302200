import React,{useState} from 'react'
import { Link } from "gatsby"
import "../../pages/pricing.css"
let classNames = require("classnames");

const PricingCard = ({contactUs=true}) => {
    const [durationToggle, setDurationToggle] = useState('monthly')


    let starterPrice
    let plusPrice
    let proPrice
    let premiumPrice
    let btnAnnualToggleClass = classNames({
        " btn btn-solid-outline-secondary ": durationToggle !== "annual",
        "btn btn-primary":
        durationToggle === "annual",
      });
    let btnMonthlyToggleClass = classNames({
    " btn btn-solid-outline-secondary ": durationToggle !== "monthly",
    "btn btn-primary":
    durationToggle === "monthly",
    });
    let btnDiscountToggleClass = classNames({
        "discountToggleGrey": durationToggle !== "annual",
        "discountToggle":
        durationToggle === "annual",
        });
    if(durationToggle === 'monthly'){
        starterPrice = (<h4 className='pricing-plan-card_price'>$9.99/month </h4>)
        plusPrice = (<h4 className='pricing-plan-card_price'>$49.99/month</h4>)
        proPrice = (<h4 className='pricing-plan-card_price'>$99.99/month</h4>)
        premiumPrice = (<h4 className='pricing-plan-card_price'>$249.99/month</h4>)
    }else{
        starterPrice = (
            <div>
                <h4 className='pricing-plan-card_price'>$19/month</h4>
                <h5 className='pricing-plan-card_priceDuration '>Billed annually</h5>
            </div>
        
        
        )
        plusPrice = (
            <div>
                 <h4 className='pricing-plan-card_price'>$49/month </h4>
                 <h5 className='pricing-plan-card_priceDuration '>Billed annually</h5>
            </div>)

        proPrice = (
            <div>
                  <h4 className='pricing-plan-card_price'>$99/month</h4>
                  <h5 className='pricing-plan-card_priceDuration '>Billed annually</h5>
            </div>
      
        )
        premiumPrice = (
            <div>
                  <h4 className='pricing-plan-card_price'>$300/month</h4>
                  <h5 className='pricing-plan-card_priceDuration '>Billed annually</h5>
            </div>
      
        )
    }

    let contactUsContent
    if(contactUs){
        contactUsContent = (
            <div className="pricing-plan-card">
                <div>
                    <h3 className='pricing-plan-card_title'>CUSTOM PLAN</h3>
                    <h4 className='pricing-plan-card_price'>Contact Support</h4>
                    <p className='pricing-plan-card_para'>Custom plans available</p>
                    {/* <ul className="pricing-plan-card_bullet">
                        <li>Flexible plans for any size</li>
                    </ul> */}
                </div>
                <div className='pricing-plan-card__button-container'>
                    <Link  className='btn btn-primary --max-width' to="/book-demo">Let's Talk</Link>
                </div>

            </div>
        )
    }else{
        
    }
    return (
        <div className='pricing-header_container'>
        {/* <h2 className='merchants-header_titleBig '>Savings!</h2> */}
        <h2 className='pricing-header_title'  >Recover lost revenue & see positive ROI in 30 days</h2>
        {/* <h2 className='pricing-header_title'>Sizify pays for itself within 1 week through return cost savings</h2> */}
        {/* <div className='pricing-duration-container'>   
        
            <button className={btnMonthlyToggleClass} onClick={()=>{setDurationToggle('monthly')}}>Monthly</button>
            <button className={btnAnnualToggleClass} onClick={()=>{setDurationToggle('annual')}}>Annual</button>
            <h3 className={btnDiscountToggleClass} >Save 20% with annual plans</h3>
        </div> */}
 
        <div className='pricing-plan-card__grid-container'>
    
            {/* <div className="pricing-plan-card">
                <div>
                    <h3 className='pricing-plan-card_title'>BASIC PLAN</h3>
                    <h4 className='pricing-plan-card_price'>Free</h4>
                    <p className='pricing-plan-card_para'>Free for up to 250 pageviews / month </p>
                    <ul className="pricing-plan-card_bullet">
                        <li>250 pageviews / month</li>
                    </ul>
                </div>
                <div className='pricing-plan-card__button-container'>
                    <a className='btn btn-primary --max-width'  href="https://apps.shopify.com/sizify">Get Started</a>
                </div>
            </div> */}
            <div className="pricing-plan-card">
                <div>
                    <h3 className='pricing-plan-card_title'>STARTER PLAN</h3>
                    {starterPrice}
                    <p className='pricing-plan-card_para'>Up to 50,000 pageviews / month (Est 15K visitors) </p>
                    {/* <ul className="pricing-plan-card_bullet">
                        <li>50,000 pageviews / month</li>
                    </ul> */}
                </div>
                <div className='pricing-plan-card__button-container'>
                    <a  className='btn btn-primary --max-width' href="https://www.shopify.com/login?redirect=/api/auth&api_key=fe588676a72eee0d55f4ebbe38589d74">Start 14-day Free Trial</a>
                </div>

            </div>
            <div className="pricing-plan-card">
                <div>
                    <h3 className='pricing-plan-card_title'>PLUS PLAN</h3>
                    {plusPrice}
                    <p className='pricing-plan-card_para'>Up to 100,000 pageviews / month (Est 30K visitors) </p>
                    {/* <ul className="pricing-plan-card_bullet">
                        <li>100,000 pageviews / month</li>
                    </ul> */}
                </div>
                <div className='pricing-plan-card__button-container'>
                    <a  className='btn btn-primary --max-width' href="https://www.shopify.com/login?redirect=/api/auth&api_key=fe588676a72eee0d55f4ebbe38589d74">Start 14-day Free Trial</a>
                </div>

            </div>
            <div className="pricing-plan-card">
                <div>
                    <h3 className='pricing-plan-card_title'>PRO PLAN</h3>
                    {proPrice}
                    <p className='pricing-plan-card_para'>Up to 250,000 pageviews / month (Est 75K visitors) </p>
                    {/* <ul className="pricing-plan-card_bullet">
                        <li>250,000 pageviews / month</li>
                    </ul> */}
                </div>
                <div className='pricing-plan-card__button-container'>
                    <a  className='btn btn-primary --max-width' href="https://www.shopify.com/login?redirect=/api/auth&api_key=fe588676a72eee0d55f4ebbe38589d74">Start 14-day Free Trial</a>
                </div>

            </div>
            {/* <div className="pricing-plan-card">
                <div>
                    <h3 className='pricing-plan-card_title'>PREMIUM PLAN</h3>
                    {premiumPrice}
                    <p className='pricing-plan-card_para'>Up to 500,000 pageviews / month </p>
               
                </div>
                <div className='pricing-plan-card__button-container'>
                    <a  className='btn btn-primary --max-width' href="https://www.shopify.com/login?redirect=/api/auth&api_key=fe588676a72eee0d55f4ebbe38589d74">Start 14-day Free Trial</a>
                </div>

            </div> */}
            {contactUsContent}
        </div>
    </div>
    )
}

export default PricingCard
